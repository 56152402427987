//== Import library
import {Outlet, Navigate, useLocation, Location} from 'react-router-dom';

//== Import Auth Context values
import {useAuth} from "../context/AuthContext";

const PrivateRoute = () => {
    let location: Location = useLocation();
    const {getIsLoggedIn} = useAuth();
    const isAuth = getIsLoggedIn();

    return isAuth ? <Outlet/> : <Navigate to={'/'} state={{from: location}} replace={true}/>;
};

export default PrivateRoute;