import Lottie from 'lottie-react';
import {Container} from 'react-bootstrap';
import styles from './FallbackError.module.scss';
import FallbackErrorLottie from '../../assets/lottie-animations/something-went-wrong.json';
const FallbackError = ({resetErrorBoundary}:any) => {
    return (
        <div className={styles.FallbackErrorWrap}>
            <Container>
                <Lottie
                    animationData={FallbackErrorLottie}
                    loop={true}
                    className={styles.lottieWrap}
                />
                <p>Something went wrong!</p>
                <button onClick={resetErrorBoundary}>Try Again</button>
            </Container>
        </div>
    );
};

export default FallbackError;