import {createContext, useContext, useState} from "react";

//== Create Modal context
const ModalViewContext = createContext<any>(null);

//== Use context values
export const useModal = () => {
    const context = useContext(ModalViewContext);
    if (!context) {
        throw new Error(`useModal must be used within a ModalProvider`);
    }
    return context;
};

//== Context values provider
const ModalProvider = ({children}: any) => {
    //== Join modal
    const [showJoinForm, setShowJoinForm] = useState(false);
    const handleJoinFormClose = () => setShowJoinForm(false);
    const handleJoinFormShow = () => setShowJoinForm(true);

    const [showReportModal, setShowReportModal] = useState(false);
    const handleReportModalClose = () => setShowReportModal(false);
    const handleReportModalShow = () => setShowReportModal(true);

    const [showShareModal, setShowShareModal] = useState(false);
    const handleShareModalClose = () => setShowShareModal(false);
    const handleShareModalShow = () => setShowShareModal(true);

    const [showCardShareModal, setShowCardShareModal] = useState(false);
    const [showCardShareID, setShowCardShareID] = useState('');
    const handleCardShareModalClose = () => setShowCardShareModal(false);
    const handleCardShareModalShow = (id: any) => {
        setShowCardShareID(id);
        setShowCardShareModal(true);
    };

    //== Delete Account Modal
    const [showDeleteAccountModal, setShowDeleteAccountModal] = useState(false);
    const handleDeleteAccountModalClose = () => setShowDeleteAccountModal(false);
    const handleDeleteAccountModalShow = () => setShowDeleteAccountModal(true);


    //== User Profile Image Change Modal
    const [showProfileEditModal, setShowProfileEditModal] = useState(false);
    const handleProfileEditModalClose = () => setShowProfileEditModal(false);
    const handleProfileEditModalShow = () => setShowProfileEditModal(true);

    //== User Profile Cover Change Modal
    const [showCoverEditModal, setShowCoverEditModal] = useState(false);
    const handleCoverEditModalClose = () => setShowCoverEditModal(false);
    const handleCoverEditModalShow = () => setShowCoverEditModal(true);

    //== Image Transparency Check Modal
    const [showStickerModal, setShowStickerModal] = useState(false);
    const handleStickerModalClose = () => setShowStickerModal(false);
    const handleStickerModalShow = () => setShowStickerModal(true);

    //== Delete Resource Modal
    const [showDeleteResourceModal, setShowDeleteResourceModal] = useState(false);
    const handleDeleteResourceModalClose = () => setShowDeleteResourceModal(false);
    const handleDeleteResourceModalShow = () => setShowDeleteResourceModal(true);

    //== User Profile Picture Rve Modal
    const [removePicture, setRemovePicture] = useState(false);
    const handleRemovePictureModalClose = () => setRemovePicture(false);
    const handleRemovePictureModalShow = () => setRemovePicture(true);


    //== Provider values
    const values: any = {
        showJoinForm,
        handleJoinFormClose,
        handleJoinFormShow,
        showReportModal,
        handleReportModalClose,
        handleReportModalShow,
        showShareModal,
        handleShareModalClose,
        handleShareModalShow,
        showCardShareModal,
        handleCardShareModalClose,
        handleCardShareModalShow,
        showCardShareID,
        showDeleteAccountModal,
        handleDeleteAccountModalClose,
        handleDeleteAccountModalShow,
        showProfileEditModal,
        handleProfileEditModalClose,
        handleProfileEditModalShow,
        showCoverEditModal,
        handleCoverEditModalClose,
        handleCoverEditModalShow,
        showStickerModal,
        handleStickerModalClose,
        handleStickerModalShow,
        showDeleteResourceModal,
        handleDeleteResourceModalClose,
        handleDeleteResourceModalShow,
        removePicture,
        handleRemovePictureModalClose,
        handleRemovePictureModalShow
    };

    return (
        <ModalViewContext.Provider value={values}>
            {children}
        </ModalViewContext.Provider>
    )
};

export default ModalProvider;