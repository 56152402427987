import Classes from './SocialLoginFailed.module.scss';
import {Link} from 'react-router-dom';
const SocialLoginFailed = () => {
    return (
        <div className={'container d-flex justify-content-center mt-4 mt-md-5'}>
            <div className={`${Classes.loginFailWrap} text-center`}>
                <h2 className={'mb-4 mb-md-5'}>Sorry, Login Failed.Try Again</h2>
                <Link
                    to={'/'}
                    className={'g-btn g-btn-danger text-decoration-none'}
                >
                    Go Home
                </Link>
            </div>
        </div>
    );
};

export default SocialLoginFailed;