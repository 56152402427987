import ModalProvider from './ModalContext';
import AlertProvider from './AlertContext';
import AuthProvider from './AuthContext';
import MixProvider from './MixContext';

const AppProvider = ({children}: any) => {
    return (
        <AuthProvider>
            <AlertProvider>
                <ModalProvider>
                    <MixProvider>
                        {children}
                    </MixProvider>
                </ModalProvider>
            </AlertProvider>
        </AuthProvider>
    );
};

export default AppProvider;