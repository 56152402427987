import React from 'react';
import ReactDOM from 'react-dom/client';
// @ts-ignore
import {unstable_HistoryRouter as HistoryRouter} from "react-router-dom";
import {createBrowserHistory} from 'history';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import ScrollToTop from './components/scroll-top/ScrollToTop';
import AppProvider from './context';
import App from './App';

//== Styles
import 'react-loading-skeleton/dist/skeleton.css';
import './index.scss';

const root: ReactDOM.Root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

const queryClient: QueryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 3 * 60 * 1000,
            retry: 3
        }
    }
});

root.render(
    <HistoryRouter history={createBrowserHistory()}>
        <ScrollToTop/>
        <QueryClientProvider client={queryClient}>
            <AppProvider>
                <App/>
            </AppProvider>
        </QueryClientProvider>
    </HistoryRouter>
);
