import {useEffect} from "react";
import {NavigateFunction, useNavigate} from "react-router-dom";
import {useAuth} from "../../../context/AuthContext";

const SocialLogin = () => {
    const navigate: NavigateFunction = useNavigate();
    const query: URLSearchParams = new URLSearchParams(window.location.search);
    const token: any = query.get("token");

    //== Get value from local storage
    const {setStoredToken} = useAuth();

    useEffect(() => {
        let mount: boolean = true;
        if (mount) {
            //== Save token in local storage
            setStoredToken(token);
            navigate("/dashboard/uploads");
        }

        return () => {
            mount = false;
        };
        // eslint-disable-next-line
    }, [token]);

    return <></>;
};

export default SocialLogin;
