import RootLayout from './layout/RootLayout';
import useGoogleAnalytics from './hooks/useGoogleAnalytics';

const App = () => {
    //==Google Analytics
    useGoogleAnalytics();

    return <RootLayout/>
};

export default App;