import ReactGA from "react-ga"

const TRACKING_ID = `${process.env.REACT_APP_GOOGLE_ANALYTICS_KEY}`;

function init() {
    // const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === "development"
    ReactGA.initialize(TRACKING_ID, {debug: false});
}

function sendEvent(action: any, category: any, label: any) {
    ReactGA.event({action, category, label});
}

function sendPageView(path: any) {
    ReactGA.set({page: path});
    ReactGA.pageview(path);
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {init, sendEvent, sendPageView};