import Classes from './TabLoader.module.scss';
const TabLoader = () => {
    return (
        <div className={`${Classes.tabLoaderWrap}`}>
            <div className={Classes.loader}>
                <svg xmlns="http://www.w3.org/2000/svg" width="60.235" height="61.002" viewBox="0 0 60.235 61.002">
                    <g transform="translate(-114.937 -249.858)">
                        <path d="M144.672,310.86c-1,0-2.01-.048-3-.145a1.5,1.5,0,0,1,.292-2.986c.893.087,1.805.131,2.709.131a27.241,27.241,0,0,0,16.855-5.769,1.5,1.5,0,1,1,1.84,2.369A30.208,30.208,0,0,1,144.672,310.86Z"
                              fill="#f15a5a"/>
                        <path d="M128.749,306.1a1.5,1.5,0,0,1-.824-.248,30.389,30.389,0,0,1-12.948-18.5,1.5,1.5,0,0,1,2.921-.686,27.407,27.407,0,0,0,11.677,16.683,1.5,1.5,0,0,1-.826,2.753Z"
                              fill="#a70dff"/>
                        <path d="M171.056,293.911a1.484,1.484,0,0,1-.623-.136,1.5,1.5,0,0,1-.741-1.988,27.6,27.6,0,0,0,1.005-20.338,1.5,1.5,0,1,1,2.838-.971,30.606,30.606,0,0,1-1.114,22.557A1.5,1.5,0,0,1,171.056,293.911Z"
                              fill="#1ee6a9"/>
                        <path d="M116.576,274.65a1.5,1.5,0,0,1-1.454-1.872,30.394,30.394,0,0,1,13.313-18.241,1.5,1.5,0,0,1,1.6,2.538,27.4,27.4,0,0,0-12.007,16.446A1.5,1.5,0,0,1,116.576,274.65Z"
                              fill="#fff35c"/>
                        <path d="M164.615,260.806a1.494,1.494,0,0,1-1.032-.411,27.381,27.381,0,0,0-18.911-7.535c-.7,0-1.447.027-2.163.083a1.5,1.5,0,1,1-.233-2.991c.794-.062,1.625-.1,2.4-.092a30.372,30.372,0,0,1,20.974,8.357,1.5,1.5,0,0,1-1.032,2.589Z"
                              fill="#2ebdff"/>
                    </g>
                </svg>
            </div>
        </div>
    );
};

export default TabLoader;