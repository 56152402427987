import {createContext, useContext, useEffect, useState} from 'react';

//== Create Alert context
const AlertViewContext = createContext<any>(null);

//== Use context values
export const useAlert = () => {
    const context = useContext(AlertViewContext);
    if (!context) {
        throw new Error(`useModal must be used within a AlertViewProvider`);
    }
    return context;
};

const AlertProvider = ({children}: any) => {
    const [alert, setAddAlert] = useState({
        alertType: '',
        alertMessage: '',
    });

    //== Provider values
    const values: any = {
        alert,
        setAddAlert
    };

    useEffect(() => {
        let alertDismissDelay = setTimeout(() => setAddAlert({alertType: '', alertMessage: ''}), 3000);
        return () => {
            clearTimeout(alertDismissDelay);
        };
    }, [alert]);

    return (
        <AlertViewContext.Provider value={values}>
            {children}
        </AlertViewContext.Provider>
    )
};

export default AlertProvider;