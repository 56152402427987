import {lazy, Suspense} from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import PageLoader from '../components/page-loader/PageLoader';
import SocialLogin from '../components/login-signup-form/social-login/SocialLogin';
import SocialLoginFailed from '../components/login-signup-form/social-login/SocialLoginFailed';
import TabLoader from '../components/tab-loader/TabLoader';

//== Lazy Loading
const Home = lazy(() => import('../pages/home/Home'));
const Categories = lazy(() => import('../pages/categories/Categories'));
const Popular = lazy(() => import('../pages/popular/Popular'));
const Search = lazy(() => import('../pages/search/Search'));
const SingleDetails = lazy(() => import('../pages/single-details/SingleDetails'));
const AllCategories = lazy(() => import('../pages/all-categories/AllCategories'));
const UserDashBoard = lazy(() => import('../pages/user-dashboard/UserDashBoard'));
const NotFound = lazy(() => import('../pages/not-found/NotFound'));
const Artist = lazy(() => import('../pages/artist/Artist'));
const SearchStickers = lazy(() => import('../pages/search-stickers/SearchStickers'));
const FrequentlyAsked = lazy(() => import('../pages/faq/FrequentlyAsked'));
const ForgotPassword = lazy(() => import('../pages/forgot-password/ForgotPassword'));
const Upload = lazy(() => import('../pages/upload/Upload'));
const UploadFinalize = lazy(() => import('../pages/upload/finalize/UploadFinalize'));
const EditGif = lazy(() => import('../pages/edit-gif/EditGif'));
const CreateFinalize = lazy(() => import('../pages/create/finalize/CreateFinalize'));
const CreateTrimFinalize = lazy(() => import('../pages/create/finalize/CreateTrimFinalize'));
const Create = lazy(() => import('../pages/create/Create'));
const TermsConditions = lazy(() => import('../pages/terms-conditions/TermsConditions'));
const PrivacyPolicy = lazy(() => import('../pages/privacy-policy/PrivacyPolicy'));
const CopyrightPolicy = lazy(() => import('../pages/copyright-policy/CopyrightPolicy'));
const ComunityGuidelines = lazy(() => import('../pages/comunity-guideline/ComunityGuidelines'));
const CookiePreferences = lazy(() => import('../pages/cookie-preferences/CookiePreferences'));
const Dmca = lazy(() => import('../pages/dmca/Dmca'));
const DeveloperGuideline = lazy(() => import('../pages/developer-guideline/DeveloperGuideline'));
const AboutUs = lazy(() => import('../pages/about-us/AboutUs'));
const AllArtist = lazy(() => import('../pages/all-artist/AllArtist'));
const EmbedGif = lazy(() => import('../pages/embed-gif/EmbedGif'));
//== Dashboard Component
const Uploads = lazy(() => import('../components/user/Uploads'));
const Favorites = lazy(() => import( '../components/user/Favorites'));
const SensitiveContents = lazy(() => import( '../components/user/SensitiveContents'));
const AccountSettings = lazy(() => import( '../components/user/AccountSettings'));
const ChannelSettings = lazy(() => import( '../components/user/ChannelSettings'));
const PasswordSettings = lazy(() => import( '../components/user/PasswordSettings'));


const Router = () => {
    return (
        <Routes>
            <Route path={'*'} element={<NotFound/>}/>
            <Route path={'/social-login/success'} element={<SocialLogin/>}/>
            <Route path={'/social-login/failed'} element={<SocialLoginFailed/>}/>
            <Route path={'/'} element={
                <Suspense fallback={<PageLoader/>}>
                    <Home/>
                </Suspense>
            }/>
            <Route
                path={'/search/:slug'}
                element={
                    <Suspense fallback={<PageLoader/>}>
                        <Search/>
                    </Suspense>
                }/>
            <Route
                path={'/category/:slug'}
                element={
                    <Suspense fallback={<PageLoader/>}>
                        <Categories/>
                    </Suspense>
                }
            />
            <Route
                path={'/popular'}
                element={
                    <Suspense fallback={<PageLoader/>}>
                        <Popular/>
                    </Suspense>
                }
            />
            <Route
                path={'/gif/:slug/:filePath'}
                element={
                    <Suspense fallback={<PageLoader/>}>
                        <SingleDetails/>
                    </Suspense>
                }
            />
            <Route
                path={'/all-categories'}
                element={
                    <Suspense fallback={<PageLoader/>}>
                        <AllCategories/>
                    </Suspense>
                }
            />
            <Route
                path={'/stickers/:slug'}
                element={
                    <Suspense fallback={<PageLoader/>}>
                        <SearchStickers/>
                    </Suspense>
                }
            />
            <Route
                path={'/artist/:username'}
                element={
                    <Suspense fallback={<PageLoader/>}>
                        <Artist/>
                    </Suspense>
                }
            />
            <Route
                path={'/all-artist'}
                element={
                    <Suspense fallback={<PageLoader/>}>
                        <AllArtist/>
                    </Suspense>
                }
            />
            <Route
                path={'/forgot-password'}
                element={
                    <Suspense fallback={<PageLoader/>}>
                        <ForgotPassword/>
                    </Suspense>
                }
            />
            <Route
                path={'/upload'}
                element={
                    <Suspense fallback={<PageLoader/>}>
                        <Upload/>
                    </Suspense>
                }
            />
            <Route
                path={'/create'}
                element={
                    <Suspense fallback={<PageLoader/>}>
                        <Create/>
                    </Suspense>
                }
            />
            <Route
                path={'/faq'}
                element={
                    <Suspense fallback={<PageLoader/>}>
                        <FrequentlyAsked/>
                    </Suspense>
                }
            />
            <Route
                path={'/terms-conditions'}
                element={
                    <Suspense fallback={<PageLoader/>}>
                        <TermsConditions/>
                    </Suspense>
                }
            />
            <Route
                path={'/privacy-policy'}
                element={
                    <Suspense fallback={<PageLoader/>}>
                        <PrivacyPolicy/>
                    </Suspense>
                }
            />
            <Route
                path={'/copyright-policy'}
                element={
                    <Suspense fallback={<PageLoader/>}>
                        <CopyrightPolicy/>
                    </Suspense>
                }
            />
            <Route
                path={'/community-guidelines'}
                element={
                    <Suspense fallback={<PageLoader/>}>
                        <ComunityGuidelines/>
                    </Suspense>
                }
            />
            <Route
                path={'/cookie-preferences'}
                element={
                    <Suspense fallback={<PageLoader/>}>
                        <CookiePreferences/>
                    </Suspense>
                }
            />
            <Route
                path={'/dmca'}
                element={
                    <Suspense fallback={<PageLoader/>}>
                        <Dmca/>
                    </Suspense>
                }
            />
            <Route
                path={'/developer-guideline'}
                element={
                    <Suspense fallback={<PageLoader/>}>
                        <DeveloperGuideline/>
                    </Suspense>
                }
            />
            <Route
                path={'/about-us'}
                element={
                    <Suspense fallback={<PageLoader/>}>
                        <AboutUs/>
                    </Suspense>
                }
            />

            <Route
                path="/embed/:slug"
                element={
                    <Suspense fallback={<PageLoader/>}>
                        <EmbedGif/>
                    </Suspense>
                }
            />

            <Route path={'/*'} element={<PrivateRoute/>}>
                <Route path={'dashboard/'} element={<Navigate to={'/'}/>}/>
                <Route path={'dashboard/'} element={
                    <Suspense fallback={<PageLoader/>}>
                        <UserDashBoard/>
                    </Suspense>
                }>
                    <Route
                        path={'uploads'}
                        element={
                            <Suspense fallback={<TabLoader/>}>
                                <Uploads/>
                            </Suspense>
                        }
                    />
                    <Route
                        path={'favourites'}
                        element={
                            <Suspense fallback={<TabLoader/>}>
                                <Favorites/>
                            </Suspense>
                        }
                    />
                    <Route
                        path={'sensitive-contents'}
                        element={
                            <Suspense fallback={<TabLoader/>}>
                                <SensitiveContents/>
                            </Suspense>
                        }
                    />
                    <Route
                        path={'account-settings'}
                        element={
                            <Suspense fallback={<TabLoader/>}>
                                <AccountSettings/>
                            </Suspense>
                        }
                    />
                    <Route
                        path={'channel-settings'}
                        element={
                            <Suspense fallback={<TabLoader/>}>
                                <ChannelSettings/>
                            </Suspense>
                        }
                    />
                    <Route
                        path={'password-settings'}
                        element={
                            <Suspense fallback={<TabLoader/>}>
                                <PasswordSettings/>
                            </Suspense>
                        }
                    />
                </Route>

                <Route
                    path={'upload/finalize'}
                    element={
                        <Suspense fallback={<PageLoader/>}>
                            <UploadFinalize/>
                        </Suspense>
                    }
                />
                <Route
                    path={'create/finalize'}
                    element={
                        <Suspense fallback={<PageLoader/>}>
                            <CreateFinalize/>
                        </Suspense>
                    }
                />
                <Route
                    path={'create/trim-finalize'}
                    element={
                        <Suspense fallback={<PageLoader/>}>
                            <CreateTrimFinalize/>
                        </Suspense>
                    }
                />
                <Route
                    path={'edit-gif/:slug'}
                    element={
                        <Suspense fallback={<PageLoader/>}>
                            <EditGif/>
                        </Suspense>
                    }
                />
            </Route>
        </Routes>
    );
};

export default Router;