import {lazy} from 'react';
import Router from '../routes/router';
import {SkeletonTheme} from 'react-loading-skeleton';
import FallbackError from '../components/fallback-error/FallbackError';
import {ErrorBoundary} from 'react-error-boundary';
import {QueryErrorResetBoundary} from '@tanstack/react-query';
import {useMixContext} from '../context/MixContext';
import {useLocation, Location} from 'react-router-dom';
//== Lazy Components
const Header = lazy(() => import('./header/Header'));
const SearchBar = lazy(() => import('./search-bar/SearchBar'));
const CookiePolicy = lazy(() => import('../components/cookie-policy/CookiePolicy'));
const AlertBox = lazy(() => import('../components/alert-box/AlertBox'));

const RootLayout = () => {
    const location: Location = useLocation();
    const {embedPath} = useMixContext();
    return (
        <SkeletonTheme
            baseColor="#171717"
            highlightColor="#1f1f1f"
            width="100%"
            height="100%"
        >
            {location?.pathname !== `/embed/${embedPath}` ? (
                <header>
                    <Header/>
                    <SearchBar/>
                </header>
            ) : null}
            <main>
                <QueryErrorResetBoundary>
                    {({reset}) => (
                        <ErrorBoundary
                            onReset={reset}
                            fallbackRender={({resetErrorBoundary}) => (
                                <FallbackError resetErrorBoundary={() => resetErrorBoundary()}/>
                            )}
                        >
                            <Router/>
                        </ErrorBoundary>
                    )}
                </QueryErrorResetBoundary>
                {location?.pathname !== `/embed/${embedPath}` ? (
                    <>
                        <AlertBox/>
                        <CookiePolicy/>
                    </>
                ) : null}
            </main>
        </SkeletonTheme>
    );
};

export default RootLayout;