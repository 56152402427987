import {createContext, useContext, useState} from 'react';

//== Create Alert context
const MixContext = createContext<any>(null);

//== Use context values
export const useMixContext = () => {
    const context = useContext(MixContext);
    if (!context) {
        throw new Error(`useMixContext must be used within a MixContextProvider`);
    }
    return context;
};

const MixProvider = ({children}: any) => {
    const [stickerCat, setStickerCat] = useState(false);
    const [inputLength, setInputLength] = useState('');
    const [showLogin, setShowLogin] = useState(false);
    const [embedPath, setEmbedPath] = useState('');
    const [relatedGifCount, setRelatedGifCount] = useState(1);
    //== Provider values
    const values: any = {
        stickerCat,
        setStickerCat,
        inputLength,
        setInputLength,
        showLogin,
        setShowLogin,
        embedPath,
        setEmbedPath,
        relatedGifCount,
        setRelatedGifCount
    };

    return (
        <MixContext.Provider value={values}>
            {children}
        </MixContext.Provider>
    );
};

export default MixProvider;