import {useEffect} from 'react';
import { useLocation } from "react-router-dom"
import GoogleAnalytics from "../components/google-analytics/GoogleAnalytics"

const useGoogleAnalytics = () => {
    const location = useLocation()

    useEffect(() => {
        GoogleAnalytics.init()
    }, [])

    useEffect(() => {
        const currentPath = location.pathname + location.search;
        GoogleAnalytics.sendPageView(currentPath);
    }, [location])
}

export default useGoogleAnalytics;