import {createContext, useCallback, useContext, useLayoutEffect} from 'react';
import decode from 'jwt-decode';
import useLocalStorage from '../hooks/useLocalStorage';

const AuthContext = createContext<any>(null);

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error(`useAuth must be used within a AuthProvider`);
    }
    return context;
};

const AuthProvider = ({children}: any) => {
    //== Get value from local storage
    const [storedToken, setStoredToken] = useLocalStorage<string | null>('@accessToken:', '');
    const [storedUserData, setStoredUserData] = useLocalStorage<object>('@user:', {});

    const getToken = useCallback(() => {
        return storedToken;
    }, [storedToken]);

    //== Logout / SignOut functionality
    const logOut = useCallback(() => {
        setStoredToken(null);
        setStoredUserData({});
        window.location.replace('/');
        window.location.reload();
        // eslint-disable-next-line
    }, []);

    const isTokenExpired = useCallback((token: string) => {
        try {
            const decoded: any = decode(token);
            const expirationTime: any = ((decoded?.exp * 1000) - 60000);
            if (Date.now() >= expirationTime) {
                logOut();
            }
        } catch (err) {
            return false;
        }
    }, [logOut]);


    //== Check token validity function for login
    const getIsLoggedIn = useCallback(() => {
        const token = getToken();
        return !!token && !isTokenExpired(token);
    }, [getToken, isTokenExpired])

    //== Initial render before dom ready
    useLayoutEffect(() => {
        getIsLoggedIn();
        // eslint-disable-next-line
    }, [getIsLoggedIn]);


    const values: any = {
        storedToken,
        setStoredToken,
        storedUserData,
        setStoredUserData,
        getToken,
        getIsLoggedIn,
        logOut,
    };

    return (
        <AuthContext.Provider value={values}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthProvider;